<!--
  作者：顾先生
-->
<template>
  <div class="Search">
    <!--    公共头部-->
    <PublicTop></PublicTop>
    <!--    内容搜索-->
    <div class="searchBox">
      <!--      搜索按钮-->
      <div class="searchBtnBox">
        <input type="text" placeholder="请输入您要查询的公司名称" class="searchInput" v-model="company">
        <div class="searchBtn" @click="SearchBtn"><van-icon name="search" size="0.5rem"/> 查询</div>
      </div>
      <!--    加入有什么用-->
      <div class="font" v-if="isShow1">
        <p class="font_p">加入立信企业计划有什么用？</p>
        <p class="font_p1">1、可获得立信企业计划专属荣誉牌匾，将好的信用形象展现给客户！荣誉牌匾是对您企业从成立以来信用的认可，更体现您持续守信经营的意愿；</p>
        <p class="font_p1">2、可获得立信企业计划公示服务，可在网上展示信用形象。在立信企业计划官网查询到企业的信用信息；</p>
        <p class="font_p1">3、可让信用更有价值。让到访公司的客户一眼就能看到您是信用企业，降低信用宣传的成本。</p>
      </div>
      <!--    公司展示信息-->
      <div class="companyBox" v-if="isShow2">
        <p class="companyBox_p1">以下是为您搜索到关于"<span style="color: #da2010;font-weight: bold">{{ showInputName }}</span>"的信息</p>
        <!-- 搜索提示 -->
        <van-empty image="search" description="没有此公司" v-if="meiyoucigognsi"/>
        <div class="companyBoxInfo" v-for="(item,k) in CompanyArry" :key="k">
<!--          <div class="companyBoxInfo_left">-->
<!--            <p class="companyBoxInfo_left_p1">{{ item.company }}</p>-->
<!--            <p class="companyBoxInfo_left_p2">证书类型：<span style="color: #da2010">{{ item.zhengshutype }}</span></p>-->
<!--            <p class="companyBoxInfo_left_p2">申请牌匾类型：<span style="color: #da2010">{{ item.shenqingtype }}</span></p>-->
<!--            <p class="companyBoxInfo_left_p2">申请日期：<span style="color: #da2010">{{ item.date }}</span></p>-->
<!--          </div>-->
          <div class="companyBoxInfo_left">
            <p class="companyBoxInfo_left_p1">{{ item.company }}</p>
          </div>
          <div class="companyBoxInfo_right">
<!--            <p class="companyBoxInfo_right_p1">联系电话：{{ item.phone }}****</p>-->
            <p class="companyBoxInfo_right_p2" @click="showPopup(item.company,item.phone,item.zhengshutype,item.shenqingtype,item.date)">立即查询进度</p>
          </div>
        </div>
        <van-pagination v-model="currentPage" :total-items=this.total :items-per-page=this.CompanyArry.length+1 :show-page-size="3" @change="change"/>
      </div>
    </div>
    <!--    弹出层-->
    <van-popup v-model="show">
      <div class="TanBox">
        <p class="TanBox1">{{ showPopupName }}</p>
        <p class="TanBox2">{{ showPopupPhone }}****<van-password-input
          :value="passwordValue"
          :focused="true"
          :length="4"
          @focus="showKeyboard"
        /></p>
        <p class="TanBox3">请输入手机号后四位进行安全验证</p>
        <button class="TanBoxBtn" @click="TanBoxBtn">立即查询</button>
      </div>
    </van-popup>
    <!--    输入数字键盘-->
    <van-number-keyboard
      :show="showkeyboard"
      z-index="9999"
      @blur="showkeyboard = false"
      @input="onInput"
      @delete="onDelete"
    />
    <!--    底部-->
    <publicBottom></publicBottom>
    <!--    loading加载-->
    <publicLoading :is-loading="isShowOk"></publicLoading>
  </div>
</template>

<script>
// 引入公共头部
import PublicTop from '@/components/PublicTop'
// 引入公共底部
import publicBottom from '@/components/publicBottom'
import publicLoading from '@/components/publicLoading'
import axios from 'axios'
import qs from 'qs'
import { Dialog } from 'vant'
export default {
  name: 'Search',
  // 注册组件
  components: { PublicTop, publicBottom, publicLoading },
  // 注册方法
  methods: {
    // 搜索公司名
    SearchBtn () {
      if (this.company.length < 2) {
        Dialog.alert({
          message: '请输入大于2个字符的公司名，方便为您精确查找',
          theme: 'round-button',
          confirmButtonColor: '#116bff'
        })
        return
      }
      this.Page = 1
      this.currentPage = 1
      this.isShowOk = true
      const company = this.company
      const page = this.Page
      const pageNum = this.PageNum
      const pList = qs.stringify({ company, page, pageNum })
      axios.post('https://msg.zxpb.cn/zqx/sms.smscheck/search', pList)
        .then(({ data }) => {
          this.total = data.total
          if (data.code === 200) {
            setTimeout(() => {
              this.meiyoucigognsi = false
              this.isShowOk = false
              this.isShow1 = false
              this.isShow2 = true
              this.showInputName = this.company
              this.CompanyArry = data.data
            }, 500)
          } else if (data.code === 500) {
            this.isShowOk = false
            Dialog.alert({
              message: '抱歉、没有此公司',
              theme: 'round-button',
              confirmButtonColor: '#116bff'
            }).then(() => {
              // on close
              this.showInputName = this.company
              this.meiyoucigognsi = true
              this.CompanyArry = []
            })
          }
        }).catch(err => {
          this.isShowOk = false
          Dialog.alert({
            message: '抱歉、没有此公司' + err,
            theme: 'round-button',
            confirmButtonColor: '#116bff'
          }).then(() => {
            // on close
          })
        })
    },
    // 弹窗输入手机号后四位
    showPopup (companyName, companyPhone, zhengshutype, shenqingtype, date) {
      this.zhengshutype = zhengshutype
      this.shenqingtype = shenqingtype
      this.date = date
      this.showPopupPhone = companyPhone
      this.showPopupName = companyName
      this.show = true
      this.showkeyboard = true
    },
    // 页码数
    change () {
      this.Page = this.currentPage
      this.isShowOk = true
      const company = this.company
      const page = this.Page
      const pageNum = this.PageNum
      const pList = qs.stringify({ company, page, pageNum })
      axios.post('https://msg.zxpb.cn/zqx/sms.smscheck/search', pList)
        .then(({ data }) => {
          this.total = data.total
          if (data.code === 200) {
            setTimeout(() => {
              this.meiyoucigognsi = false
              this.isShowOk = false
              this.isShow1 = false
              this.isShow2 = true
              this.CompanyArry = data.data
            }, 500)
          } else if (data.code === 500) {
            this.isShowOk = false
            Dialog.alert({
              message: '抱歉、没有此公司',
              theme: 'round-button',
              confirmButtonColor: '#116bff'
            }).then(() => {
              // on close
              this.meiyoucigognsi = true
              this.CompanyArry = []
            })
          }
        }).catch(err => {
          this.isShowOk = false
          Dialog.alert({
            message: '抱歉、没有此公司' + err,
            theme: 'round-button',
            confirmButtonColor: '#116bff'
          }).then(() => {
          // on close
          })
        })
    },
    // 手机后四位输入
    onInput (key) {
      this.passwordValue = (this.passwordValue + key).slice(0, 6)
    },
    // 手机后四位输入
    onDelete () {
      this.passwordValue = this.passwordValue.slice(0, this.passwordValue.length - 1)
    },
    // 立即提交
    TanBoxBtn () {
      this.isShowOk = true
      // 公司名
      const company = this.showPopupName
      // 手机号
      const phone = this.showPopupPhone + this.passwordValue
      // this.$router.push({
      //   path: `/logistics/${this.showPopupPhone + this.passwordValue}` // 跳转路由
      // })
      const pList = qs.stringify({ company, phone })
      axios.post('https://msg.zxpb.cn/zqx/sms.smscheck/getinfo', pList)
        .then(({ data }) => {
          // console.log(data)
          if (data.code === 200) {
            setTimeout(() => {
              this.isShowOk = false
              const ObjInfo = {
                // 公司名称
                Gongsi: this.showPopupName,
                // 公司证书类型
                zhengshuleixing: this.zhengshutype,
                // 公司牌匾类型
                paibianleixing: this.shenqingtype,
                // 公司日期
                gongsiDate: this.date,
                // 付款日期
                fukuanriqi: data.date,
                // 物流状态
                zhaungtai: data.stat,
                // 快递公司
                kuaidiGongsi: data.wuliutype,
                // 快递单号
                yundanhao: data.number,
                // 快递物流
                wuliu: data.list,
                // 物流显示否
                ListShow: data.listisShow
              }
              const ObjArr = JSON.stringify(ObjInfo)
              console.log(data)
              this.$router.push({
                path: '/logistics/' + encodeURIComponent(ObjArr) // 跳转路由
              })
            }, 500)
          } else if (data.code === 500) {
            this.isShowOk = false
            Dialog.alert({
              message: '抱歉、输入有误',
              theme: 'round-button',
              confirmButtonColor: '#116bff'
            }).then(() => {
              // on close
            })
          }
        }).catch(err => {
          console.log('请求失败' + err)
          this.isShowOk = false
          Dialog.alert({
            message: '抱歉、请重新查询' + err,
            theme: 'round-button',
            confirmButtonColor: '#116bff'
          }).then(() => {
          // on close
          })
        })
    },
    // 手机输入后四位点击事件
    showKeyboard () {
      this.showkeyboard = true
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      Page: 1,
      PageNum: 4,
      // 公司名称
      company: '',
      // 显示隐藏查询1
      isShow1: true,
      // 显示隐藏查询2
      isShow2: false,
      // 立即查询进度按钮
      show: false,
      // 分页
      currentPage: 1,
      // 手机后四位
      passwordValue: '',
      // 键盘弹起事件
      showkeyboard: false,
      // loading
      isShowOk: false,
      // 查询后返回的数据
      CompanyArry: [],
      // 总数
      total: null,
      // 输入给客户的公司信息
      showInputName: '',
      // 弹出层的公司信息
      showPopupName: '',
      // 弹出层的公司电话
      showPopupPhone: '',
      // 没有此公司
      meiyoucigognsi: false,
      // 物流公司证书类型
      zhengshutype: '',
      // 物流公司牌匾类型
      shenqingtype: '',
      // 物流公司日期类型
      date: ''
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
  },
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
.Search{
  // 内容搜索
  .searchBox{
    margin: 10px auto 0px;
    width: 90%;
    padding-bottom: 20px;
    background-color: white;
    overflow: hidden;
    // 搜索按钮
    .searchBtnBox{
      display: flex;
      margin: 15px auto 0px;
      width: 310px;
      height: 34px;
      // 搜索框
      .searchInput{
        font-size: 13px;
        text-align: center;
        width: 230px;
        height: 100%;
        border: 1px solid #ac2317;
        border-right: none;
      }
      // 按钮
      .searchBtn{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: #ffffff;
        width: 80px;
        height: 100%;
        border-radius: 0px 3px 3px 0px;
        background-color: #ff422f;
      }
    }
    // 介绍计划
    .font{
      margin-top: 70px;
      width: 100%;
      height: 300px;
      padding: 0px 12px 0px 12px;
      .font_p{
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        color: #353535;
      }
      .font_p1{
        margin-top: 30px;
        font-size: 14px;
        color: #363636;
      }
    }
    // 公司展示信息
    .companyBox{
      margin: 15px auto 0px;
      width: 320px;
      .companyBox_p1{
        font-size: 14px;
      }
      .companyBoxInfo{
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: #f2f3f7;
        border-radius: 3px;
        .companyBoxInfo_left{
          padding: 5px;
          flex: 1.5;
          border-radius: 3px;
          line-height: 20px;
          .companyBoxInfo_left_p1{
            font-size: 13px;
          }
          .companyBoxInfo_left_p2{
            font-size: 12px;
          }
        }
        .companyBoxInfo_right{
          flex: 0.7;
          border-radius: 3px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          .companyBoxInfo_right_p1{
            font-size: 11px;
          }
          .companyBoxInfo_right_p2{
            width: 80px;
            text-align: center;
            line-height: 30px;
            height: 30px;
            background-color: #ff422f;
            font-size: 12px;
            color: white;
            border-radius: 5px;
          }
        }
      }
      /deep/.van-pagination__item{
        color: black;
      }
      /deep/.van-pagination__item--active{
        color: red;
        background-color: white;
      }
      /deep/.van-pagination__item:active{
        background-color: white;
      }
    }
  }
  // 弹出层
  .TanBox{
    padding: 10px;
    width: 320px;
    text-align: center;
    .TanBox1{
      text-align: center;
      font-size: 23px;
      font-weight: bold;
      color: #ff422f;
      margin-bottom: 5px;
    }
    .TanBox2{
      text-align: center;
      font-size: 20px;
      margin-bottom: 5px;
    }
    .TanBox3{
      text-align: center;
      font-size: 16px;
    }
    .TanBoxBtn{
      width: 120px;
      height: 35px;
      color: white;
      font-size: 16px;
      text-align: center;
      background-color: #ff422f;
      border-radius: 3px;
      border: none;
    }
  }
}
</style>
