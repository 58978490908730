<!--
  作者：顾先生
-->
<template>
  <div class="PublicTop">
    <div class="top">
      <img src="../assets/img/3.png" class="top_img1">
      <img src="../assets/img/fontStyle.png" class="top_img2">
      <img src="../assets/img/fontStyle2.png" class="top_img3">
    </div>
    <!--    牌匾进度查询-->
    <div class="font">牌匾进度查询</div>
  </div>
</template>

<script>
export default {
  name: 'PublicTop',
  // 注册组件
  components: {},
  // 注册方法
  methods: {},
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {

    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {},
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
.PublicTop{
  .top{
    position: relative;
    width: 100%;
    height: 150px;
    background: url("../assets/img/bgc.png") no-repeat;
    background-size: 100%;
    .top_img1{
      width: 45px;
      position: absolute;
      top: 45px;
      left: 59px;
    }
    .top_img2{
      width: 200px;
      position: absolute;
      top: 45px;
      left: 106px;
    }
    .top_img3{
      width: 160px;
      position: absolute;
      top: 100px;
      left: 110px;
    }
  }
  .font{
    width: 100%;
    height: 40px;
    background-color: #ac2317;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 40px;
    color: #ffffff;
  }
}
</style>
