<!--
  作者：顾先生
-->
<template>
  <div class="logistics">
    <!--    公共头部-->
    <PublicTop></PublicTop>
    <!--    主体部分-->
    <div class="logisticsBox">
      <!--      公司名-->
      <div class="companyBox">
        <p class="companyBox_p1">{{ listArr.Gongsi }}</p>
        <div class="companyBoxOne">
          <p class="companyBoxOne_p1">证书类型：<span style="color:#da2010;font-weight: bold">{{ listArr.zhengshuleixing }}</span></p>
          <p class="companyBoxOne_p1">申请牌匾类型：<span style="color:#da2010;font-weight: bold">{{ listArr.paibianleixing }}</span></p>
          <p class="companyBoxOne_p1">申请日期：<span style="color:#da2010;font-weight: bold">{{ listArr.gongsiDate }}</span></p>
          <van-collapse v-model="activeNames1">
            <van-collapse-item title="荣誉牌匾进度信息" name="1">
              <!--      物流进度查询-->
              <div class="InfoBox">
                <van-steps direction="vertical" :active="listArr.zhaungtai-1">
                  <van-step v-if="listArr.zhaungtai === 1">
                    <h3>订单已支付，即将进入复审流程</h3>
                    <p>{{ listArr.fukuanriqi }}</p>
                    <p style="opacity: 0;">2016-07-12 12:40</p>
                  </van-step>
                  <van-step v-else>
                    <h3>订单已支付，已进入复审流程</h3>
                    <p>{{ listArr.fukuanriqi }}</p>
                    <p style="opacity: 0;">2016-07-12 12:40</p>
                  </van-step>
                  <van-step v-if="listArr.zhaungtai === 2">
                    <h3>立信企业计划复审已通过，即将进入荣誉牌匾制作</h3>
                    <p style="opacity: 0;">2016-07-11 10:00</p>
                    <p style="opacity: 0;">2016-07-11 10:00</p>
                  </van-step>
                  <van-step v-else>
                    <h3>立信企业计划复审已通过，已进入制作荣誉牌匾制作</h3>
                    <p style="opacity: 0;">2016-07-11 10:00</p>
                    <p style="opacity: 0;">2016-07-11 10:00</p>
                  </van-step>
                  <van-step v-if="listArr.zhaungtai === 3">
                    <h3>牌匾制作中，即将快递寄出</h3>
                    <p>牌匾制作约7个工作日左右，请耐心等候。</p>
                    <p style="opacity: 0;">2016-07-11 10:00</p>
                  </van-step>
                  <van-step v-else>
                    <h3>牌匾已制作完成，快递已寄出</h3>
                    <p style="opacity: 0;">2016-07-11 10:00</p>
                  </van-step>
                  <van-step v-if="listArr.zhaungtai === 4">
                    <h3>您的牌匾已交付<span style="color: red">{{ listArr.kuaidiGongsi }}</span></h3>
                    <p>(运单号：{{ listArr.yundanhao }})</p>
                    <p style="font-size: 12px">您可长按复制快递运单号，查询快递邮寄进度</p>
                  </van-step>
                  <van-step v-else>
                    <h3>您的牌匾还未制作成功，成功后将更新快递信息</h3>
                    <p>请您耐心等待</p>
                    <p style="opacity: 0;">约1-3个工作日左右 会有快递信息,请耐心等候</p>
                  </van-step>
                </van-steps>
              </div>
            </van-collapse-item>
          </van-collapse>
          <van-collapse v-model="activeNames2">
            <van-collapse-item title="快递物流进度信息" name="1">
              <!--      物流进度查询-->
              <div class="InfoBox">
                <van-steps direction="vertical" v-if="listArr.ListShow === 1">
                  <van-step v-for="(item,k) in listArr.wuliu" :key="k">
                    <h3>{{ item.context }}</h3>
                    <p>{{ item.ftime }}</p>
                  </van-step>
                </van-steps>
                <van-steps direction="vertical" v-else-if="listArr.ListShow === 0">
                  <van-step>
                    <h3>暂无物流信息</h3>
                    <p style="opacity: 0;">2016-07-12 12:40</p>
                  </van-step>
                </van-steps>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>

    </div>
    <!--    公共底部-->
    <publicBottom></publicBottom>
    <!--    客服电话-->
    <div class="botm_fixed" id="botm_fixed" @click="clickPhone">
      <a href="tel:4000642818" ref="telPhone" style="display:none;"></a>
      <img src="../assets/img/icon5.png" class="botm_fixed_img1">
    </div>
  </div>
</template>

<script>
// 引入公共头部
import PublicTop from '@/components/PublicTop'
// 引入公共底部
import publicBottom from '@/components/publicBottom'
import { Dialog } from 'vant'
export default {
  name: 'logistics',
  // 注册组件
  components: { PublicTop, publicBottom },
  // 注册方法
  methods: {
    // 拨打客服电话
    clickPhone () {
      Dialog.confirm({
        title: '温馨提示',
        message: '确定拨打客服电话：400-064-2818 吗？',
        confirmButtonColor: '#116bff'
      })
        .then(() => {
          // on confirm
          this.$refs.telPhone.click()
        })
        .catch(() => {
          // on cancel
        })
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      listArr: {},
      activeNames1: ['1'],
      activeNames2: ['1']
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {},
  // 页面内容渲染完成
  mounted () {
    const list = decodeURIComponent(this.$route.params.obj)
    this.listArr = JSON.parse(list)
  }
}
</script>

<style scoped lang="less">
.logistics{
  .logisticsBox{
    margin: 10px auto 0px;
    padding-bottom: 20px;
    width: 90%;
    background-color: white;
    overflow: hidden;
    // 公司名
    .companyBox{
      padding-bottom: 10px;
      margin: 10px auto 0px;
      width: 320px;
      background-color: #f2f3f7;
      overflow: hidden;
      .companyBox_p1{
        margin-top: 5px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #da2010;
      }
      .companyBoxOne{
        margin-top: 10px;
        .companyBoxOne_p1{
          text-indent: 2em;
          line-height: 20px;
          font-size: 12px;
        }
      }
    }
    // 物流查询
    .InfoBox{
      margin: 20px auto 0px;
      width: 320px;
    }
  }
  .botm_fixed {
    width: 60px;
    height: 60px;
    position: fixed;
    right: 0px;
    bottom: 100px;
  }
  .botm_fixed .botm_fixed_img1 {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}
</style>
