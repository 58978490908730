<!--
  作者：顾先生
-->
<template>
  <div class="Bottom_container">
    <!--    底部布局-->
    <div class="btmBox">
      <div class="btmBoxBox">
        <!-- 底部上面 -->
        <div class="upper">
          <p class="upper_p1">关于中企信</p>
          <p class="upper_p2">中企信（中雄世纪征信有限公司）是批准备案的信用服务机构，有强大的企业大数据算法能力和互联网技术能力，依托“全国企业信用查询平台”，为用户提供信用查询、检测、评定等服务。</p>
        </div>
        <!-- 底部下面 -->
        <div class="lower">
          <div class="left">
            <a href="" class="left_p1" id="left_p1" @click="GongShang"><img src="../assets/img/67.png" alt="">&nbsp;工商登记信息</a>
            <p class="left_p2">客服电话:400-064-2818</p>
          </div>
          <div class="left">
            <a href="" class="left_p1" @click="XinYong"><img src="../assets/img/70.png" alt="">&nbsp;&nbsp;信用机构备案信息</a>
            <!--            <p class="left_p2">客服微信：zhongqixinke</p>-->
            <p class="left_p2">公众号:中雄世纪中企信</p>
          </div>
        </div>

        <div class="btmBoxImg">
          <img src="../assets/img/font.png" alt="" class="btmBoxImg1">
          <img src="../assets/img/66.png" alt="" class="btmBoxImg2">
        </div>
        <!--        <div class="housekeeper">-->
        <!--          <span class="housekeeper_span">企信服务管家由第三方信用机构中雄世纪征信有限公司独家运营</span>-->
        <!--        </div>-->
      </div>
    </div>

    <!--  工商登记信息-->
    <van-popup v-model="GongShangShow" round closeable :style="{ width:'95%',height:'90%' }">
      <img src="../assets/img/1.jpg" style="width: 100%;height: 100%;">
    </van-popup>
    <!--  信用机构信息-->
    <van-popup v-model="XinYongJiGou" round closeable :style="{ width:'95%',height:'45%' }">
      <img src="../assets/img/tan2.png" style="width: 100%;height: 100%;">
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'publicBottom',
  // 注册组件
  components: {},
  // 注册方法
  methods: {
    // 信用机构弹出
    XinYong (e) {
      e.preventDefault()
      this.XinYongJiGou = true
    },
    // 工商弹出层
    GongShang (e) {
      e.preventDefault()
      this.GongShangShow = true
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      // 工商信息的显示隐藏
      GongShangShow: false,
      // 信用机构显示隐藏
      XinYongJiGou: false
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {},
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
@width_reset:340px;
//@import "@/style/index.css";
.Bottom_container{
  // 底部布局
  .btmBox{
    //margin-bottom:35px;
    margin-top: 10px;
    width: 100%;
    height: 265px;
    background-color: #ac2317;
    overflow: hidden;
    .btmBoxBox{
      width:@width_reset;
      height: 200px;
      margin: 20px auto 0px;
      border: 4px solid #ebbb95;
      border-radius: 14px;
      .btmBoxImg{
        margin-top:30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btmBoxImg1{
          margin-bottom:10px;
          width: 280px;
          height: 12px;
        }
        .btmBoxImg2{
          width: 260px;
          height: 10px;
        }
      }
    }
    // 底部上面
    .upper{
      width: 280px;
      //height: 90px;
      margin: 0 auto;
      margin-top: 6px;
      color: white;
      font-weight: 500;
      .upper_p1{
        text-align: center;
        font-size: 15px;
        margin-bottom:8px;
        .upper_p1_span2{
          font-size: 16px;
          padding-left: 3px;
          font-weight: normal;
          color: #ffffff;
          font-family: FZDBSK;
        }
      }
      .upper_p2{
        text-indent: 2em;
        font-size: 12px;
        line-height: 22px;
      }
    }
    .lower{
      width: 300px;
      height: 45px;
      margin: 0 auto;
      margin-bottom: 8.7px;
      margin-top: 1vw;
      // margin-top: (@spacing_3/@rootSize);
      display: flex;
      justify-content: space-between;
      .left{
        width: 140px;
        height: 45px;
        text-align: center;
        line-height: 25px;
        .left_p1{
          font-size: 11px;
          color: white;
          img{
            width: 20px;
            height:20px;
            vertical-align: middle;
          }
        }
        .left_p2{
          font-size:11px;
          color: white;
        }
      }
    }
    // 新增 2022-12-16号
    .jiandu{
      width: 100%;
      height: 30px;
      line-height:30px;
      text-align: center;
      font-size: 16px;
      color: white;
    }
    // 新增 2022-12-16号
  }
  // 底部文字
  .bom_img{
    width: 280px;
    height: 20px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom:10px;
    img{
      width: 100%;
      height:15px;
    }
  }
}
</style>
