<!--
  作者：顾先生
-->
<template>
  <div class="Loading_container">
    <van-overlay :show="isLoading" z-index="9999999999">
      <div class="wrapper">
        <div class="block"><van-loading size="24px" vertical>请稍等...</van-loading></div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: 'publicLoading',
  // 注册组件
  components: {},
  // 注册方法
  methods: {

  },
  // 父子传值
  props: {
    isLoading: {
      type: Boolean
    }
  },
  // 数据渲染
  data () {
    return {
      // show: false
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {},
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
.Loading_container{
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 120px;
    height: 120px;
  }
}
</style>
